<template>
  <div>
    <b-form @submit="onSubmit" v-if="show">
      <b-row>
        <b-col cols="12" md="6">
          <x-form-group title="Período Fiscal">
            <x-select-periodo-fiscal class="mb-3" @input="onChangePeriodoFiscal" modulo="Reporte Saldos Partidas"/>
          </x-form-group>
        </b-col>

        <b-col cols="12" md="6">
          <x-form-group title="Fecha de corte">
            <b-form-datepicker
              id="form-fecha-corte"
              v-model="fecha_corte"
              class="mb-2"
              placeholder="Fecha de corte"
              required
            ></b-form-datepicker>
          </x-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" md="6">
          <x-form-group title="Fuente de financiamiento">
            <x-select
              ref="xselectfuentesfinanciamiento"
              v-model="fuente_financiamiento_id"
              :dispatchPath="null"
              getterListPath="fuenteFinanciamientoModule/getList"
              prepend-key
              :formatListWith="['id', 'clave', 'nombre']"
              :disabled="isLoading || !isFuentesFinanciamientoAvailable"
              no-validate
              no-prepend
              defaultText="-- TODAS --"
            />
          </x-form-group>
        </b-col>

        <b-col cols="12" md="6">
          <x-form-group title="Proyecto">
            <x-select
              ref="xselectproyectos"
              v-model="proyecto_id"
              :dispatchPath="null"
              getterListPath="proyectoModule/getList"
              prepend-key
              :formatListWith="['id', 'clave', 'nombre']"
              :disabled="isLoading || !isProyectosAvailable"
              no-validate
              no-prepend
              defaultText="-- TODOS --"
            />
          </x-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" md="6">
          <x-form-group title="Unidad Administrativa">
            <x-select
              ref="xselectunidadesadministrativas"
              v-model="unidad_administrativa_id"
              :dispatchPath="null"
              getterListPath="administrativeUnitModule/getAdministrativeUnits"
              prepend-key
              :formatListWith="['id', 'clave', 'nombre']"
              :disabled="isLoading || !isUnidadesAdministrativasAvailable"
              no-validate
              no-prepend
              defaultText="-- TODAS --"
            />
          </x-form-group>
        </b-col>

        <b-col cols="12" md="6">
          <x-form-group title="Tipo de Gasto">
            <x-select
              ref="xselecttiposgasto"
              v-model="tipo_gasto_id"
              :dispatchPath="null"
              getterListPath="tipoGastoModule/getList"
              prepend-key
              :formatListWith="['id', 'clave', 'nombre']"
              :disabled="isLoading || !isTiposGastoAvailable"
              no-validate
              no-prepend
              defaultText="-- TODOS --"
            />
          </x-form-group>
        </b-col>
      </b-row>

      <b-form-group>
        <div class="w-100 d-flex justify-content-between">
          <b-button type="submit" size="sm" :disabled="loadingSelects || loading" variant="primary">Consultar</b-button>
          <b-button :disabled="loadingSelects || loading" @click="onExport()" size="sm" variant="success">Exportar</b-button>
        </div>
      </b-form-group>
    </b-form>

    <div class="card card-header-actions">
      <div class="card-header">
        Reporte
      </div>
      <div class="card">
        <b-overlay :show="loading">
          <template #overlay>
            <loading />
          </template>

          <b-table
            :fields="fields"
            :items="getReporte"
            striped
            bordered
            responsive
            hover
            stacked="lg"
            show-empty
          >
            <template #cell(clave)="data">
              <div v-if="data.item.nivel === 0"></div>
              <div v-else-if="data.item.nivel === 1" class="text-black text-md font-weight-bold">{{ data.item.clave.clave }}</div>
              <div v-else-if="data.item.nivel === 2" class="text-black-75 text-md font-weight-bold">{{ data.item.clave.clave }}</div>
              <div v-else-if="data.item.nivel === 3" class="text-black-75 text-sm font-weight-bold">{{ data.item.clave.clave }}</div>
              <div v-else-if="data.item.nivel === 4" class="text-black-50 text-sm" v-b-tooltip.hover>{{ data.item.clave.clave }}</div>
            </template>
            <template #cell(nombre)="data">
              <div v-if="data.item.nivel === 0"></div>
              <div v-else-if="data.item.nivel === 1" class="text-black text-md font-weight-bold">{{ data.item.clave.nombre }}</div>
              <div v-else-if="data.item.nivel === 2" class="text-black-75 text-md font-weight-bold">- {{ data.item.clave.nombre }}</div>
              <div v-else-if="data.item.nivel === 3" class="text-black-75 text-sm font-weight-bold">-- {{ data.item.clave.nombre }}</div>
              <div v-else-if="data.item.nivel === 4" class="text-black-50 text-sm" v-b-tooltip.hover>--- {{ data.item.clave.nombre }}</div>
            </template>
            <template #cell(importes.disponible)="data">
              <div v-if="data.value === ''"></div>
              <div v-else-if="data.item.nivel === 1 && data.value >= 0" class="text-black text-md font-weight-bold float-right" >{{ $formatNumber(data.value) }}</div>
              <div v-else-if="data.item.nivel === 1 && data.value <  0" class="text-danger text-md font-weight-bold float-right" >{{ $formatNumber(data.value) }}</div>
              <div v-else-if="data.item.nivel === 2 && data.value >= 0" class="text-black-75 text-md font-weight-bold float-right" >{{ $formatNumber(data.value) }}</div>
              <div v-else-if="data.item.nivel === 2 && data.value <  0" class="text-danger text-md font-weight-bold float-right" >{{ $formatNumber(data.value) }}</div>
              <div v-else-if="data.item.nivel === 3 && data.value >= 0" class="text-black-75 text-sm font-weight-bold float-right" >{{ $formatNumber(data.value) }}</div>
              <div v-else-if="data.item.nivel === 3 && data.value <  0" class="text-danger text-sm font-weight-bold float-right" >{{ $formatNumber(data.value) }}</div>
              <div v-else-if="data.item.nivel === 4 && data.value >= 0" class="text-black-50 text-sm float-right" >{{ $formatNumber(data.value) }}</div>
              <div v-else-if="data.item.nivel === 4 && data.value <  0" class="text-danger text-sm float-right" >{{ $formatNumber(data.value) }}</div>
            </template>
          </b-table>
        </b-overlay>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'ReportePlaneacionSaldosPartidas',

  data () {
    return {
      loading: false,

      fields: [
        { key: 'clave', label: 'Clave' },
        { key: 'nombre', label: 'Nombre' },
        // Importes
        { key: 'importes.disponible', label: 'Disponible' }
      ],

      showModal: false,

      isLoading: false,

      show: true
    }
  },

  computed: {
    ...mapGetters('reportePlaneacionSaldosPartidasModule', [
      'getReporte', 'getClaves', 'getParams'
    ]),
    periodo_fiscal_id: {
      get () {
        return this.getParams.periodo_fiscal_id
      },
      set (value) {
        this.setPeriodoFiscalId(value)
      }
    },
    fecha_corte: {
      get () {
        return this.getParams.fecha_corte
      },
      set (value) {
        this.setFechaCorte(value)
      }
    },
    fuente_financiamiento_id: {
      get () {
        return this.getParams.fuente_financiamiento_id
      },
      set (value) {
        this.setFuenteFinanciamientoId(value)
      }
    },
    proyecto_id: {
      get () {
        return this.getParams.proyecto_id
      },
      set (value) {
        this.setProyectoId(value)
      }
    },
    unidad_administrativa_id: {
      get () {
        return this.getParams.unidad_administrativa_id
      },
      set (value) {
        this.setUnidadAdministrativaId(value)
      }
    },
    tipo_gasto_id: {
      get () {
        return this.getParams.tipo_gasto_id
      },
      set (value) {
        this.setTipoGastoId(value)
      }
    },

    ...mapGetters('fiscalPeriodsModule', [
      'getAllFiscalPeriods'
    ]),

    ...mapGetters({
      isPeriodosAvailable: 'fiscalPeriodsModule/getAvailableList',
      isFuentesFinanciamientoAvailable: 'fuenteFinanciamientoModule/isListAvailable',
      isProyectosAvailable: 'proyectoModule/getAvailableList',
      isUnidadesAdministrativasAvailable: 'administrativeUnitModule/hasAdministrativeUnits',
      isTiposGastoAvailable: 'tipoGastoModule/isListAvailable'
    }),

    loadingSelects () {
      return !this.isFuentesFinanciamientoAvailable ||
        !this.isProyectosAvailable ||
        !this.isUnidadesAdministrativasAvailable ||
        !this.isTiposGastoAvailable
    }
  },

  methods: {
    ...mapMutations('reportePlaneacionSaldosPartidasModule', [
      'setPeriodoFiscalId',
      'setFechaCorte',
      'setFuenteFinanciamientoId',
      'setProyectoId',
      'setUnidadAdministrativaId',
      'setTipoGastoId'
    ]),

    loadCatalogo (periodo_id, isAvailable, dispatchRoute, title) {
      if (!isAvailable) {
        this.$store.dispatch(
          dispatchRoute,
          periodo_id
        ).then(result => {
          if (result.error) this.$notify(result, title)
        })
      }
    },

    async loadCatalogos () {
      // Obtenemos período fiscal activo
      // const periodo = await this.$getPeriodoFiscalActivo()
      const periodo = {
        id: 1
      }

      // Fuentes de Financiamiento
      this.loadCatalogo(periodo.id, this.isFuentesFinanciamientoAvailable,
        'fuenteFinanciamientoModule/getAll', 'Fuentes de Financiamiento')

      // Proyectos
      this.loadCatalogo(periodo.id, this.isProyectosAvailable,
        'proyectoModule/getProyectos', 'Proyectos')

      // Unidades Administrativas
      this.loadCatalogo(periodo.id, this.isUnidadesAdministrativasAvailable,
        'administrativeUnitModule/getAdministrativeUnits', 'Unidades Administrativas')

      // Tipos de Gasto
      this.loadCatalogo(periodo.id, this.isTiposGastoAvailable,
        'tipoGastoModule/getAll', 'Tipos de Gasto')

      // Fecha de corte
      const today = new Date()
      const month = (today.getMonth() + 1).toString().padStart(2, '0')
      const day = (today.getDay() + 1).toString().padStart(2, '0')
      this.fecha_corte = today.getFullYear() + '-' + month + '-' + day
    },

    async onChangePeriodoFiscal (periodo_fiscal_id) {
      // this.loading = true
      // const { error, message } = await this.$store.dispatch('reporteAnaliticoPptoEgresosModule/getReporteAnaliticoPptoEgresos', periodo_fiscal_id)
      // this.loading = false

      this.periodo_fiscal_id = periodo_fiscal_id

      this.loadCatalogos()

      // if (error) return this.$notify({ error, message }, 'Error')
    },

    async onSubmit (event) {
      event.preventDefault()

      this.loading = true
      const { error, message } = await this.$store.dispatch('reportePlaneacionSaldosPartidasModule/loadReporte', this.getParams)
      this.loading = false

      if (error) return this.$notify({ error, message }, 'Error')
    },

    async onExport () {
      this.loading = true
      const getval = await this.$store.dispatch('reportePlaneacionSaldosPartidasModule/exportReporte', this.getParams)
      this.loading = false
      if (!getval.error) {
        this.$onDownload(
          getval,
          'reporte_planeacion-saldos_partidas.xlsx'
        )
      } else {
        this.$notify(getval, 'Exportación')
      }
    }
  }
}
</script>
